if (document.querySelector('#roi-calculator')) {
  Vue.component('calculator', {
    delimiters: ['{(', ')}'],
    props: {
      defaultIasMake: {
        type: String,
        default: 'ulstein'
      },
      defaultDataCollectionLevel: {
        type: String,
        default: 50
      },
      defaultM1: {
        type: Boolean,
        default: false
      },
      defaultAveragePowerDemand: {
        type: Number,
        default: 1200
      },
      defaultAverageAnnualSfoc: {
        type: Number,
        default: 230
      },
      defaultFuelPrice: {
        type: Number,
        default: 7
      },
      defaultExpectedFuelSaving: {
        type: Number,
        default: 10
      },
      defaultM2: {
        type: Boolean,
        default: false
      },
      defaultWeeklyOperationHours: {
        type: Number,
        default: 5
      },
      defaultOperationHourlyRate: {
        type: Number,
        default: 1100
      },
      defaultM3: {
        type: Boolean,
        default: false
      },
      ulsteinXConnectIasPrice: {
        type: Number,
        default: 0
      },
      nonUlsteinXConnectIasPrice: {
        type: Number,
        default: 65000
      },
      bluebox50SubscriptionPrice: {
        type: Number,
        default: 5000
      },
      bluebox100SubscriptionPrice: {
        type: Number,
        default: 7500
      },
      bluebox200SubscriptionPrice: {
        type: Number,
        default: 10000
      },
      bluebox500SubscriptionPrice: {
        type: Number,
        default: 12500
      },
      m1CommissioningPrice: {
        type: Number,
        default: 90000
      },
      m1SubscriptionPrice: {
        type: Number,
        default: 5000
      },
      m2CommissioningPrice: {
        type: Number,
        default: 40000
      },
      m2SubscriptionPrice: {
        type: Number,
        default: 4000
      },
      m3CommissioningPrice: {
        type: Number,
        default: 20000
      },
      m3SubscriptionPrice: {
        type: Number,
        default: 3000
      },
    },

    data: function() {
      return {
        form: {
          iasMakes: [
            { label: 'Ulstein X-Connect IAS', value: 'ulstein' },
            { label: 'non Ulstein X-Connect IAS', value: 'non-ulstein' }
          ],
          iasMake: this.defaultIasMake,
          dataCollectionLevels: [50, 100, 200, 500],
          dataCollectionLevel: this.defaultDataCollectionLevel,
          refuelM1: this.defaultM1,
          averagePowerDemand: this.defaultAveragePowerDemand,
          averageAnnualSfoc: this.defaultAverageAnnualSfoc,
          fuelPrice: this.defaultFuelPrice,
          expectedFuelSaving: this.defaultExpectedFuelSaving,
          refuelM2: this.defaultM2,
          weeklyOperationHours: this.defaultWeeklyOperationHours,
          operationHourlyRate: this.defaultOperationHourlyRate,
          refuelM3: this.defaultM3,
        }
      }
    },
  
    filters: {
      percentage: function(value) {
        if (!value) {
          return ''
        }
  
        return value + ' %'
      },
  
      currency: function(value) {
        if (!value) {
          return '- kr'
        }

        // return value + ' kr'
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' kr'
      
      },

      default: function(value) {
        if (!value) {
          return '-'
        }

        return value
      },
    },
  
    computed: {
      // Calculations
      iasMake() {
        if (this.form.iasMake === 'ulstein') {
          return this.ulsteinXConnectIasPrice
        }
  
        return this.nonUlsteinXConnectIasPrice
      },
  
      dataCollectionLevelThreeYears() {
        let price = 0
        if (this.form.dataCollectionLevel == 50) {
          price = this.bluebox50SubscriptionPrice
        }
  
        if (this.form.dataCollectionLevel == 100) {
          price = this.bluebox100SubscriptionPrice
        }
  
        if (this.form.dataCollectionLevel == 200) {
          price = this.bluebox200SubscriptionPrice
        }
  
        if (this.form.dataCollectionLevel == 500) {
          price = this.bluebox500SubscriptionPrice
        }
  
        return Math.round(price * 12 * 3)
      },
  
      dataCollectionLevelAnnual() {
        let price = 0
        if (this.form.dataCollectionLevel == 50) {
          price = this.bluebox50SubscriptionPrice
        }
  
        if (this.form.dataCollectionLevel == 100) {
          price = this.bluebox100SubscriptionPrice
        }
  
        if (this.form.dataCollectionLevel == 200) {
          price = this.bluebox200SubscriptionPrice
        }
  
        if (this.form.dataCollectionLevel == 500) {
          price = this.bluebox500SubscriptionPrice
        }
  
        return Math.round(price * 12)
      },
  
      refuelThreeYears() {
        let result = 0
  
        if (this.form.refuelM1) {
          result += this.m1CommissioningPrice + (this.m1SubscriptionPrice * 12 * 3)
        }
  
        if (this.form.refuelM2) {
          result += this.m2CommissioningPrice + (this.m2SubscriptionPrice * 12 * 3)
        }
  
        if (this.form.refuelM3) {
          result += this.m3CommissioningPrice + (this.m3SubscriptionPrice * 12 * 3)
        }
  
        if (this.form.refuelM1 && this.form.refuelM2 && this.form.refuelM3) {
          return Math.round(result * 0.75)
        }
  
        return Math.round(result)
      },
  
      refuelAnnual() {
        let result = 0
  
        if (this.form.refuelM1 && !this.form.refuelM3) {
          result += this.m1SubscriptionPrice * 12
        }
  
        if (this.form.refuelM2 && !this.form.refuelM3) {
          result += this.m2SubscriptionPrice * 12
        }
  
        if (this.form.refuelM3) {
          result += this.m3SubscriptionPrice * 12
        }
  
        if (this.form.refuelM1 && this.form.refuelM2 && this.form.refuelM3) {
          return Math.round(result * 0.75)
        }
  
        return Math.round(result)
      },
  
      // Results
      annualCostSavingFuel() {
        if (this.form.refuelM1) {
          return Math.round((this.form.averagePowerDemand * (this.form.averageAnnualSfoc / 1000) * 0.85 * 24 * 365 * this.form.fuelPrice) * (this.form.expectedFuelSaving / 100))
        }
  
        return 0
      },
  
      annualCostSavingOperations() {
        if (this.form.refuelM2) {
          return Math.round(this.form.weeklyOperationHours * this.form.operationHourlyRate * 52)
        }
  
        return 0
      },
  
      annualSubscriptionCostThreeYears() {
        return Math.round(-(this.iasMake + this.dataCollectionLevelThreeYears + this.refuelThreeYears) / 3)
      },
  
      annualSubscriptionCostFuture() {
        return Math.round(-(this.dataCollectionLevelAnnual + this.refuelAnnual))
      },
  
      companyCostSavingThreeYears() {
        return Math.round(this.annualCostSavingFuel + this.annualCostSavingOperations + this.annualSubscriptionCostThreeYears)
      },
  
      roiThreeYears() {
        return Math.round(this.companyCostSavingThreeYears / (-this.annualSubscriptionCostThreeYears) * 100)
      },
  
      companyCostSavingFuture() {
        return Math.round(this.annualCostSavingFuel + this.annualCostSavingOperations + this.annualSubscriptionCostFuture)
      },
  
      roiFuture() {
        return Math.round(this.companyCostSavingFuture / (-this.annualSubscriptionCostFuture) * 100)
      },
    },

    methods: {
      m3Changed() {
        if (this.form.refuelM3) {
          this.form.refuelM2 = true
        }
      }
    },

    template: `
      <div class="calculator">
        <div class="calculator-form">
          <div class="calculator-form-row">
            <div class="calculator-form-item">
              <label>IAS make</label>
              <div class="calculator-select">
                <select v-model="form.iasMake">
                  <option
                    v-for="make in form.iasMakes"
                    :key="make.value"
                    :value="make.value">
                    {( make.label )}
                  </option>
                </select>
                <div class="calculator-select-arrow">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
              <span class="calculator-info">Ulstein X-Connect IAS does not require additional HW</span>
            </div>

            <div class="calculator-form-item">
              <label>Data collection level</label>
              <div class="calculator-select">
                <select v-model="form.dataCollectionLevel">
                  <option
                    v-for="level in form.dataCollectionLevels"
                    :value="level">
                    {( level )}
                  </option>
                </select>
                <div class="calculator-select-arrow">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
              <span class="calculator-info">Amount of IO</span>
            </div>
          </div>

          <div class="calculator-form-item">
            <label>Refuel* M1 Fuel meter</label>
            <div class="calculator-radios">
              <label for="refuel-m1-on">
                <input type="radio" id="refuel-m1-on" :value="true" v-model="form.refuelM1">
                <span class="calculator-radio-on">On</span>
              </label>
              <label for="refuel-m1-off">
                <input type="radio" id="refuel-m1-off" :value="false" v-model="form.refuelM1">
                <span class="calculator-radio-off">Off</span>
              </label>
            </div>
          </div>

          <div class="calculator-form-row">
            <div class="calculator-form-item">
              <label>Average power demand</label>
              <input type="number" v-model="form.averagePowerDemand" :disabled="!form.refuelM1">
              <span class="calculator-info">Example: 1200KW supply vessel 90m</span>
            </div>

            <div class="calculator-form-item">
              <label>Average annual SFOC</label>
              <input type="number" v-model="form.averageAnnualSfoc" :disabled="!form.refuelM1">
              <span class="calculator-info">Specific fuel oil consumption g/kWh: 250:25-30% load.</span>
            </div>
          </div>

          <div class="calculator-form-row">
            <div class="calculator-form-item">
              <label>Marine Diesel Fuel Oil Price NOK/litre</label>
              <input type="number" v-model="form.fuelPrice" :disabled="!form.refuelM1">
            </div>

            <div class="calculator-form-item">
              <label>Expected fuel saving %</label>
              <input type="number" v-model="form.expectedFuelSaving" :disabled="!form.refuelM1">
            </div>
          </div>

          <div class="calculator-form-item">
            <label>Refuel* M2 Reporting</label>
            <div class="calculator-radios">
              <label for="refuel-m2-on">
                <input type="radio" id="refuel-m2-on" :value="true" v-model="form.refuelM2">
                <span class="calculator-radio-on">On</span>
              </label>
              <label for="refuel-m2-off">
                <input type="radio" id="refuel-m2-off" :value="false" v-model="form.refuelM2">
                <span class="calculator-radio-off">Off</span>
              </label>
            </div>
          </div>

          <div class="calculator-form-row">
            <div class="calculator-form-item">
              <label>Weekly management/operation hours for manual report work on shore</label>
              <input type="number" v-model="form.weeklyOperationHours" :disabled="!form.refuelM2">
            </div>

            <div class="calculator-form-item">
              <label>Cost management/operation hour rate NOK/h</label>
              <input type="number" v-model="form.operationHourlyRate" :disabled="!form.refuelM2">
            </div>
          </div>

          <div class="calculator-form-item">
            <label>Refuel* M3: Reporting export excel</label>
            <div class="calculator-radios">
              <label for="refuel-m3-on">
                <input type="radio" id="refuel-m3-on" :value="true" v-model="form.refuelM3" @change="m3Changed">
                <span class="calculator-radio-on">On</span>
              </label>
              <label for="refuel-m3-off">
                <input type="radio" id="refuel-m3-off" :value="false" v-model="form.refuelM3" @change="m3Changed">
                <span class="calculator-radio-off">Off</span>
              </label>
            </div>
          </div>
        </div>

        <div class="calculator-results">
          <div class="calculator-subresults">
            <div>
              <span class="calculator-subresult-label">Annual cost saving fuel: </span>
              <span class="calculator-subresult-value">{( annualCostSavingFuel | currency )}</span>
            </div>

            <div>
              <span class="calculator-subresult-label">Annual cost saving management/operations: </span>
              <span class="calculator-subresult-value">{( annualCostSavingOperations | currency )}</span>
            </div>

            <div>
              <span class="calculator-subresult-label">Annual subscription and investment cost year 1-3: </span>
              <span class="calculator-subresult-value">{( annualSubscriptionCostThreeYears | currency )}</span>
            </div>

            <div>
              <span class="calculator-subresult-label">Annual subscription cost year 4+: </span>
              <span class="calculator-subresult-value">{( annualSubscriptionCostFuture | currency )}</span>
            </div>
          </div>

          <div class="calculator-form-row">
            <div class="calculator-form-item">
              <div class="calculator-result">
                <span class="calculator-result-label">Total company annual cost saving year 1-3</span>
                <span class="calculator-result-value">{( companyCostSavingThreeYears | currency )}</span>
                <span class="calculator-result-info">Return of investment: {( roiThreeYears | percentage )}</span>
              </div>
            </div>

            <div class="calculator-form-item">
              <div class="calculator-result">
                <span class="calculator-result-label">Total company annual cost saving year 4+</span>
                <span class="calculator-result-value">{( companyCostSavingFuture | currency )}</span>
                <span class="calculator-result-info">Return of investment: {( roiFuture | percentage )}</span>
              </div>
            </div>
          </div>
        </div>
        <span class="calculator-reference">*Example based on Refuel analytics modules developed by Recogni</span>
      </div>
    `
  });

  var app = new Vue({
    el: '#roi-calculator',
  });
}