import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';

objectFitImages();
objectFitVideos();

import 'tablesort/src/sorts/tablesort.number.js';
//import FastClick from 'fastclick';
import 'foundation-sites/js/foundation';
// Custom Interchange to support webp
import './libs/foundation.interchange';

// FastClick.attach(document.body);

(function($) {

    // Share button
    const shareNetworks = {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        twitter: "https://twitter.com/intent/tweet?via=UlsteinGroup&url=",
        email: "mailto:?body=",
        linkedin: "https://www.linkedin.com/shareArticle?mini=true&url="
    };


    $(window).load(function() {
        $('img[data-bgimage').each(function() {
            $(this).css({
                'backgroundImage': $(this).data('bgimage')
            });
        });
    });

    $(function() {
        // Enable tablesorting
        $('table.sorted').each(function() {
            const tbl = new Tablesort($(this).get(0), { descending: true });
            console.log(tbl);
        });


        $("figure.bg--cover, .banner").each(function() {
            var focalpoint = $(this).data('focalpoint') || '50% 50%';

            $(this).css({
                'backgroundPosition': focalpoint
            });

        });

        $('a[data-network]').on('click', function(e) {
            let network = $(this).data('network')

            let prefix = shareNetworks[network]
            let url = window.encodeURIComponent(window.location.href)

            if (network === 'email') {
                window.location = prefix + url;
            } else {
                window.open(prefix + url, '', "resizable,width=500,height=230");
            }
        });

        // Move the modals out to the body, so that it doesn't duplicate the entries when clicking twice.
        $('.reveal-modal').each(function() {
            if ($(this).parent('body').length === 0) {
                $(this).appendTo($('body'))
            }
        });

        $(document).foundation({
            equalizer: { equalize_on_stack: true },
            orbit: {
                timer_speed: 5000
            }
        });

        // $('[data-lazyinterchange]').lazyInterchange();  

        var ns = '.fndtn.reveal'
        var scrollTop = 0;
        $(document).on('open' + ns, '[data-reveal]', function() {
            scrollTop = $(document).scrollTop();
            $(document).scrollTop(0);
            $(this).find('iframe[data-src]').each(function() {
                $(this).attr('src', $(this).data('src'));
            });
        });

        $(document).on('close' + ns, '[data-reveal]', function() {
            $(document).scrollTop(scrollTop)
        });
        $('#mainCategorySelect').on("change", function() {
            console.log($('#mainCategorySelect').value);
        })
    });

})(jQuery);