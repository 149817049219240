/* Support freeform when using blitz caching */

(($) => {
    $(() => {
        // Find the corresponding form
        var forms = $('.ulstein-form[data-handle]');

        if (forms.length > 0) {
            $(forms).each(function (index, form) {
                $.ajax({
                    // Specify the form handle in the GET parameters
                    url: '/blitz?form=' + $(form).data('handle'),
                    type: 'get',
                    dataType: 'json',
                    success: function (response) {
                        $(form).find('input[name=formHash]').val(response.hash);

                        // Update the honeypot
                        var honeypot = response.honeypot;
                        $(form).find('input[name^="freeform_form_handle"]').attr({
                            'id': honeypot.name,
                            'name': honeypot.name
                        }).val(honeypot.hash);

                        // Locate and update the csrf input
                        var csrf = response.csrf;
                        $(form).find('input[name=' + csrf.name + ']').val(csrf.value);
                    }
                });
            });
        }

    });

})(jQuery);