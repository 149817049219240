const lazyload = (elements) => {
    const setSource = element => {
        if(element.hasAttribute('data-sizes')){
            element.sizes = element.dataset.sizes;
        }

        if(element.hasAttribute('data-srcset')){
            element.srcset = element.dataset.srcset;
        } else if (element.hasAttribute('data-bgimage')) {
            element.style.backgroundImage = `url(${element.dataset.bgimage})`;
        } else {
            element.src = element.dataset.src;
        }
    };

    const onLoad = (element) => {
        let target = element;

        const bgImage = element.hasAttribute('data-bgimage');
        if (bgImage) {
            target = new Image();
        }

        target.onload = () => {
            element.style.opacity = 1;
            target.onload = null;
        };

        if (bgImage) {
            target.src = element.dataset.bgimage;
        }
    };

    if ("IntersectionObserver" in window) {
        let elementObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(function (entry) {

                if (entry.isIntersecting) {
                    let element = entry.target;

                    onLoad(element);
                    setSource(element);
                    
                    elementObserver.unobserve(element);
                }
            });
        }, { threshold: 0, rootMargin: "150px" });

        elements.forEach(element => {
            element.style.opacity = 0;
            element.style.transitionProperty = 'opacity';
            element.style.transitionDuration = '0.2s';
            elementObserver.observe(element)
        });
    } else {
        elements.forEach(img => {
            setSource(img);
        });
    }
};

const elements = document.querySelectorAll('[data-srcset],[data-bgimage],[data-src]');
lazyload(elements);
