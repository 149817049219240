/* Support adminbar when using blitz caching */

(($) => {
    $(() => {

        const queryString = {
            uri: window.location.pathname,
            params: { static: true }
        };

        const initAdminbar = () => {
            // request Admin Bar after converting array to JSON
            $.ajax({
                type: 'POST',
                url: '/actions/admin-bar/bar',
                cache: false,
                data: JSON.stringify(queryString),
                dataType: 'json',
                success: function(data) {
                    if (data.response === 'success' && typeof window.adminBarInit === "function") {
                        const body = $('body');
                        const header = body.find('>header');
                        header.append(data.content);

                        body.css({
                            'padding-top': header.outerHeight()
                        })

                        window.adminBarInit();
                    }
                },
                error: function(err) {
                    console.log("Error", err);
                }
            });
        };

        const getAdminbar = () => {
            $.ajax({
                type: 'GET',
                url: '/blitz/adminbar',
                cache: false,
                data: { uri: queryString.uri },
                success: function (data) {
                    $('head').append($(data));
                    initAdminbar();
                },
                error: function(err) {
                    console.log("Error", err);
                }
            })
        };

        getAdminbar();
    });

})(jQuery);